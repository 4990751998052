import { CurrProjectPage } from "@context/homePages";
import { ProjectPages, type ProjectPage } from "@context/server";
import { useStore } from "@nanostores/react";
import { cMerge } from "@utils/cMerge";
import type { HTMLAttributes } from "astro/types";
import type React from "react";

type Props = {
    className?: string;
    style?: React.CSSProperties;
} & HTMLAttributes<"a">;

export const IndexLink = (props: Props) => {
    const $currPage = useStore(CurrProjectPage);
    const platform = ProjectPages[$currPage];
    return (
        <a
            href={platform.externalLink}
            className={cMerge(
                "visit-link pointer-events-auto text-body-3",
                props.className
            )}
            style={props.style}
            rel="noopener"
            target="_blank"
        >
            <div className="visit-link-inner">
                <span className="whitespace-nowrap">
                    {platform.status === "coming-soon"
                        ? "COMING SOON"
                        : "VISIT"}
                </span>
                {platform.status === "live" && (
                    <>
                        <div className="w-4"></div>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 9 9"
                            aria-hidden="true"
                            className="relative w-2"
                        >
                            <path
                                className="fill-current"
                                d="M6.4531 3.54v3.84h1.84V.44h-6.98v1.82h3.88l-5.019997 5L1.4331 8.54l5.02-5Z"
                            ></path>
                        </svg>
                    </>
                )}
            </div>
        </a>
    );
};
